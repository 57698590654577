.qr_img{
    width: 90%;
    max-width: 300px;
    margin: 40px auto;

}
.qr_img svg {
    /*width: 100%;*/
    /*height: 100vh;*/
    /*max-width: 300px;*/
}