.wrapper{

}
.back_color{
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.48);
    z-index: -100;

}
.logoBackFoto
{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    /* width: 70%; */
    padding: 3px 10px;
    white-space: nowrap;
    z-index:-101;
}


.title{
    margin: 20px auto;
    text-align: center;
    font-weight: 800;
}
.text{
    margin: 0 auto 20px auto;
    text-align: left;
    width: 85%;
    max-width:420px;
}

.btn_continue{
    display: block;
    margin: 0 auto 20px auto;
}
.text p{
    margin: 5px;
}

@media all and (orientation:landscape) {
    .logoBackFoto
    {
        height: auto;
        width: 100%;
        /* width: 70%; */

    }

}
