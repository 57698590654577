
.wrapper{
    /*position: absolute;*/
    /*width: 1440px;*/
    height: 110px;
    width: 100%;
    background: #A2D9F7;
    z-index: 10004;
}

.logo{
    position: absolute;
    width: 381px;
    height: 68px;
    left: 30px;
    top: 22px;

}

.logo_up{
    position: absolute;
    width: 335px;
    height: 25px;
    /*left: 32px;*/
   

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 25px;
    /* identical to box height */


    color: #285CA6;

}


.logo_down{
    position: absolute;
    width: 400px;
    height: 49px;
    /*left: 30px;*/
    top: 18px;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 49px;
    /* identical to box height */


    color: #285CA6;

}

.merch_logo{
    display: inline-block;
    float: right;
    padding-right: 30px;
    position: relative;
    top:15px;

}
.learnLogo{
    position: relative;
    top:-30px;
    margin-right: 30px;

}
.product{
    width: 80px;
}

@media (max-width:1000px) {
    .merch_logo{
        display: none;
    }
}
@media (max-width: 700px) {
    .logo{

        left: 15px;
        top: 22px;

    }
}

@media (max-width: 500px) {
    .logo_up{
        font-size: 15px;
        left: 0;
        width: 305px;
    }
    .logo_down{
        top: 15px;
        left:0;
        font-size: 30px;
        width: 305px;
    }
    .logo{
        width: 200px;
    }
}

@media (max-width: 340px) {
    .logo_up{
        font-size: 10px;
        left: 15px;
        width: 200px;
    }
    .logo_down{
        top: 4px;
        left: 15px;
        font-size: 21px;
        width: 200px;
    }
    .logo{
        width: 200px;
    }
}

