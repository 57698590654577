.wrapper{
    margin-top: 10%;
}

.no_active_input,
.active_input{
    display: block;
    font-family: Gilroy;
    font-weight: 500;
    background: #FFFFFF;
    border: 3px solid #285CA6;
    box-sizing: border-box;
    border-radius: 20px;
    font-size: 16px;
    width: 300px;
    height: 40px;
    padding-left: 20px;
    margin: 10px auto;
    text-align: center;
}
.btn_back_quiz{
    border:3px solid #285CA6;
    font-weight: 800;
    text-align: center;
    color: #285CA6;
    line-height: 30px;
    width: 300px;
    height: 30px;
    border-radius: 20px;
    margin: 20px auto;
    cursor: pointer;
}
.no_active_input{
    border: 3px solid #a62828;
}

.warning_text_user{
    text-align: center;
    color:#a62828;
}

.btn_save{
    width: 125px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #285CA6;
    border: 3px solid #285CA6;
    border-radius: 20px;
    margin: 50px auto 20px auto;
    cursor: pointer;
    color: white;
}