.card_wrapper{

    min-height: calc(100vh - 90px);
}

.content{
    width: 80%;
    max-width: 800px;
    min-height: 100px;
    margin:10px auto 20px auto;
    left: 30px;
    top: 179px;

    border: 3px solid #285CA6;
    border-radius: 20px;

    padding: 20px;
}

.title{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;

    color: #285CA6;
}

.text{
    font-size: 20px;
    font-weight: 800;
    margin: 20px 0;
}

.btn_wrapper{
    text-align: center;
}
.btn_wrapper div{
    margin: 10px auto 20px auto;
}
.error_message_title{

    text-align: center;
    font-weight: 800;
}
.error_message{
    text-align: justify;
    padding: 10px;
    width: 90%;
    max-width: 400px;
    margin: auto;
}

