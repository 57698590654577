.wrapper{
    position: fixed;
    width: 60%;
    top:40%;
    background-color: #A2D9F7;
    z-index:110;


    left: 50%;
    transform: translate(-50%, -50%);

    width:60%;
    max-width: 300px;
    height: 250px;
    border:1px solid #285CA6;
    border-radius: 20px;

}
.modal_background{
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #9b9b9b71;
    z-index:110;
}
.btn_close{
    width: 125px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #FFFFFF;
    border: 3px solid #3681C3;
    border-radius: 20px;
    margin: 50px auto 20px auto;
    cursor: pointer;
}


.title{
    margin: 20px auto;
    width: 250px;
    text-align: center;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;

    color: #285CA6;
}


.message{
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 20px;
    color: #285CA6;
    text-align: center;
    margin-top: 30px;

}
.true_icon,
.error_icon{
    display: block;
    width: 120px;
    margin: 35px auto;
}

@media(max-width:740px){
    .close_window {
        position: static;
        display: block;
        width: 100%;
        text-align: right;

        font-size: 28px;
        margin: 5px 10px;
    }
    .title{
        display: block;
        position: static;
        text-align: center;
        font-size: 24px;
    }
    .message{
        margin: 20px;
    }

    .error_textbox_data,
    .textbox_data,
    .error_textarea_data,
    .textarea_data{
        width: 220px;
    }
}
@media(max-width:400px){

    .close_window {
        display: block;
        position: static;
        text-align: right;
        font-size: 28px;
        margin: 2px 8px;
        width: 100%;
        height: 20px;

    }
    .title{
        display: block;
        position: static;
        font-size: 20px;
        width: 100%;
        height: auto;
        margin: 20px auto;



    }
    .message{
        margin: 20px;
    }

    .error_textbox_data,
    .textbox_data,
    .error_textarea_data,
    .textarea_data{
        margin: 10px auto;
        width:95%;
    }
}
@media(max-height:620px){
    .wrapper{
        top:20px;
    }
    .form{
        max-height:300px;
    }
}

@media(max-height:400px){
    .wrapper{
        top:-10px;
        min-height:100px;
        height:94%;
        overflow-y: auto;

    }

    /* .form{
        max-height:300px;
        overflow-y: auto;
    } */
}