.wrapper{
    padding: 20px;
    border:3px solid #285CA6;
    border-radius: 20px;
    margin: 40px auto;
    width: 80%;
    max-width: 300px;
    text-align: center;
    color: #285CA6;
    font-weight: 800;
    background-color: rgba(255, 255, 255, 0.62);

}
.back_image{

}


.title{
    font-size: 22px;
    margin-bottom: 10px;
}

.description{
    font-size: 20px;
    margin-bottom: 10px;

}

.check{
    width: 30px;

    position: relative;
    top:-20px;
    left: 20px;

}