
.wrapper{

    vertical-align: middle;
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    color:#285CA6;
    font-weight: 800;
}
.game{
    font-size: 19px;
    margin-bottom: 10px;
}

.title{
    font-size: 30px;

}

.number{
    font-size: 62px;
    margin: -15px auto -10px;
}

.propmo{
    font-size: 19px;
}

.code{
    margin: 20px auto;
    padding: 10px;
    border: 3px solid silver;
    border-radius: 10px;
    font-size: 20px;
    width: 200px;

}
.description{
    font-size: 19px;
}
.wrapper_phone_map_none{
    display: block;

}
.phone{
    display: block;
    color:#285CA6;
    font-size: 19px;
    margin-top: 10px;

}
.mail span,
.phone span{
    display: inline-block;
    vertical-align: middle;
    text-decoration: underline;
    margin-left: 10px;
}
.phone_svg{
    width: 20px;
    vertical-align: middle;
}
.mail{
    display: block;
    color:#285CA6;
    text-decoration: underline;
    padding-left: 10px;
    font-size: 19px;
    margin-top: 10px;
}

.mail_svg{
    width: 20px;
    vertical-align: middle;
}